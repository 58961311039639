:root {
  --dictionary-color: #228de4;
  --script-color: #e422ca;
  --selection-bg-white: #000;
  --selection-color-white: #fff;
  --selection-bg-dark: #fff;
  --selection-color-dark: #000;
  --font-family: 'Source Code Pro', monospace;
  --font-size: 16px;
  --info-color-light: rgba(0, 0, 0, 0.3);
  --info-color-dark: rgba(255, 255, 255, 0.3);
  --font-weight-light: 400;
  --font-weight-dark: 400;
}

[data-theme="light"] {
  --background-color: #fff;
  --text-color: #000;
  --font-weight: var(--font-weight-light);
}

[data-theme="dark"] {
  --background-color: #000;
  --text-color: #fff;
  --font-weight: var(--font-weight-dark);
}

body {
  font-size: var(--font-size);
  font-family: var(--font-family);
  background-color: var(--background-color);
  color: var(--text-color);
  font-weight: var(--font-weight);
  padding: 5px;
  margin: 0;
  font-size: var(--font-size);
  overflow-x: hidden;
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

[data-theme="light"] .info {
  color: var(--info-color-light);
}

[data-theme="dark"] .info {
  color: var(--info-color-dark);
}

* {
  cursor: text;
  -webkit-tap-highlight-color: transparent;
}

a {
  cursor: pointer;
}

.directory {
  color: var(--dictionary-color);
}

.script {
  color: var(--script-color);
}

pre {
  font-family: var(--font-family);
  margin: 0;
  margin-right: 30px;
}

.about,
pre {
  float: left;
}

.input-text,
.input-text * {
  border: none;
  outline: 0;
  box-sizing: border-box;
  padding-right: 45px;
  font-weight: var(--font-weight);
  width: 50%;
  margin-left: -0.1rem;
  background-color: transparent;
  font-size: var(--font-size);
  font-family: var(--font-family);
  word-break: break-all;
  color: var(--text-color);
  display: inline;
  white-space: pre-wrap;
}

.input-lable {
  position: relative;
  left: 0;
  z-index: 1;
  width: 100%;
}

.clear {
  clear: both;
}
#main,
.content {
  line-height: 23px;
  word-wrap: break-word;
  position: relative;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
  display: inline-block;
  max-width: 100%;
  
}

.ls-dir {
  color: var(--primary-color);
}

#main a {
  color: #000;
}

#main a:hover {
  text-decoration: none;
}

.info {
  margin-bottom: -1rem;
}

.help-item {
  width: 130px;
  float: left;
}

.precolor,
.preleave,
.preoption {
  display: none;
  word-break: break-all;
}

.con-lost {
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: none;
}

.con-lost,
.con-lost > div {
  position: fixed;
  width: 100%;
}

.con-lost > div {
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 11;
}

.con-lost-text {
  font-size: 27px;
  font-weight: 700;
}

.retry {
  font-size: 18px;
  line-height: 30px;
}

#ac-tip {
  opacity: 0.3;
}

.lan-div {
  display: inline;
  box-sizing: border-box;
  margin: 0;
  border-left: 1px solid hsla(0, 0%, 100%, 0.3);
}

.input-lable,
.input-lable span {
  word-break: break-all;
}

@media (max-width: 400px) {
  .input-text,
  .input-text *,
  body {
    font-size: 15px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  border-radius: 5px;
  padding: 20px;
  width: 90%;
}

.popup h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.popup p {
  line-height: 1.5em;
}

.popup button {
  background: #000;
  color: #ffffff;
  padding: 10px 20px;
  border-color: rgba(255, 255, 255, 0.9);
  border-width: 0.5px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.non-mobile-popup {
  width: 25rem;
  height: auto;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #000;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 12px;
  z-index: 100;
  border-left: 0.1px solid rgb(255,255,255);
}

.non-mobile-popup p {
  font-size: 14px;
  line-height: 1.4em;
  margin-bottom: 10px;
  margin-top: 8px
  
}

.non-mobile-popup button {
  background: #000;
  border-color: rgba(255, 255, 255, 0.9);
  border-width: 0.1px;
  color: #ffffff;
  padding: 5px 10px;
  margin-right: 10px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
  
}

.non-mobile-popup button:hover {
  background-color: rgb(0, 0, 0);
}
